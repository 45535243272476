<template>
  <CheckNormalGoodsMobile v-if="isMobileAgent()" :item="item" @cancel="cancel" @refreshData="refreshData" />
  <CheckNormalGoodsDesktop v-else :item="item" @cancel="cancel" @refreshData="refreshData" />
</template>

<script>

export default {
  name: "CheckNormalGoods",
  components: {
    CheckNormalGoodsMobile: () => import('@/components/pos_goods/CheckNormalGoodsMobile'),
    CheckNormalGoodsDesktop: () => import('@/components/pos_goods/CheckNormalGoodsDesktop'),
  },
  props: {
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    refreshData(type) {
      this.$emit('refreshData', type)
    },
  }
}
</script>

<style scoped>

</style>
